import React from "react";
import { defer, IDeferred } from "../utilities/promise";
import { useTimeout } from "./usetimeout";

const defaultTrailingDebounceOptions: ITrailingDebounceOptions = {
  onDiscard: undefined
};

export interface ITrailingDebounceOptions {
  /**
   * Called when the delegate runs and the resulting promise settles,
   * but the result is discarded as it is no longer the latest run.
   */
  onDiscard?: () => void;
}

/**
 * Custom hook to debounce an operation using the trailing edge.
 * If a sequence of operations happen within a specified time of each other, only the last operation runs.
 */
export function useTrailingDebounce<TResult>(): {
  debounce<TArgs extends any[]>(
    delegate: (...args: TArgs) => Promise<TResult>,
    delay: number,
    options: ITrailingDebounceOptions | undefined,
    ...args: TArgs
  ): Promise<TResult>;
} {
  const pending = React.useRef<Readonly<IDeferred<TResult>> | null>(null);
  const cleanup = React.useRef<(() => void) | null>(null);

  const { setTimeout } = useTimeout();

  return { debounce: React.useCallback(debounce, [setTimeout]) };

  /**
   * Runs an operation at a delay which is cancelled/disregarded if another operation is run.
   * @param delegate Function to run
   * @param delay milliseconds delay before function execution is started
   * @param args delegate args
   * @returns Promise for the latest run
   */
  function debounce<TArgs extends any[]>(
    delegate: (...args: TArgs) => Promise<TResult>,
    delay: number,
    options: ITrailingDebounceOptions | undefined,
    ...args: TArgs
  ): Promise<TResult> {
    const { onDiscard } = options ?? defaultTrailingDebounceOptions;

    if (cleanup.current) {
      cleanup.current();
    }

    let latest = true;
    cleanup.current = () => {
      latest = false;
    };

    if (pending.current === null) {
      pending.current = defer();
    }

    const _pending = pending.current;

    const runDelegate = (...args: TArgs) =>
      delegate(...args)
        .then((value) => {
          if (latest) {
            _pending.resolve(value);

            pending.current = null;
            cleanup.current = null;
          } else if (onDiscard) {
            onDiscard();
          }
        })
        .catch((error) => {
          if (latest) {
            _pending.reject(error);

            pending.current = null;
            cleanup.current = null;
          } else if (onDiscard) {
            onDiscard();
          }
        });

    // setTimeout may run with a longer delay than specified.
    // So when the delay is zero, run it immediately, without setTimeout.
    if (delay === 0) {
      runDelegate(...args);
    } else {
      setTimeout(runDelegate, delay, ...args);
    }

    return pending.current.promise;
  }
}
