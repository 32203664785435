import { Button, IButtonProps } from "azure-devops-ui/Button";
import { ContentJustification, ContentLocation, ContentOrientation } from "azure-devops-ui/Callout";
import { FocusZone, FocusZoneDirection } from "azure-devops-ui/FocusZone";
import { Icon } from "azure-devops-ui/Icon";
import { Link } from "azure-devops-ui/Link";
import { ITooltipProps, Tooltip } from "azure-devops-ui/TooltipEx";
import { css } from "azure-devops-ui/Util";
import React from "react";
import { useHistory } from "react-router-dom";
import { Callout } from "../../common/components/callout/callout";
import { Observer } from "../../common/components/observer/observer";
import { EventContext } from "../../common/contexts/event";
import { FeatureContext } from "../../common/contexts/feature";
import { NavigationContext } from "../../common/contexts/navigation";
import { useSubscription } from "../../common/hooks/useobservable";
import { format, formatComponent } from "../../common/utilities/format";
import * as Icons from "../components/illustration/icons";
import { SessionContext } from "../contexts/session";
import { formatQuotaSize, formatSize } from "../utilities/format";
import { getPlanType, PlanType } from "../utilities/plantype";

import "./navigation.css";

const { AddNew, PeopleTitle, ToggleNavigation } = window.Resources.Common;
const {
  BuyMoreStorage,
  BuyStorage,
  ExpandCollapseAltLabel,
  FilesPage,
  FilesPageAltLabel,
  HomePage,
  HomePageAltLabel,
  PeopleAltLabel,
  PhotosPage,
  PhotosPageAltLabel,
  PremiumOneDrive,
  PurchaseLabel,
  PurchaseMoreLabel,
  QuotaPercentage,
  QuotaUsageOver,
  RecentPage,
  RecentPageAltLabel,
  RecycleBinPage,
  RecycleBinPageAltLabel,
  SharedPage,
  SharedPageAltLabel,
  StorageLabel
} = window.Resources.Navigation;

export interface INavigationProps {
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
}

export function Navigation(props: INavigationProps): React.ReactElement {
  const { expanded, setExpanded } = props;

  const eventContext = React.useContext(EventContext);
  const featureContext = React.useContext(FeatureContext);
  const navigationContext = React.useContext(NavigationContext);
  const sessionContext = React.useContext(SessionContext);

  const showHome = useSubscription(featureContext.featureEnabled("showHome"));

  // Track the router and use it for FPS navigations between pages.
  const username = sessionContext.userProfile && (sessionContext.userProfile.name || sessionContext.userProfile.preferred_username);

  return (
    <nav
      className={css(
        "photo-navigation flex-column flex-noshrink overflow-hidden",
        expanded ? "expanded padding-bottom-12 padding-right-12" : "collapsed padding-bottom-16"
      )}
    >
      <div className="flex-column overflow-hidden">
        <div className="flex-row margin-bottom-22 margin-left-16 margin-top-24">
          <Button
            ariaLabel={expanded ? AddNew : ""}
            className={css(
              "add-new-button flex-align-center overflow-hidden padding-horizontal-12",
              expanded ? "rounded-18" : "collapsed flex-justify-center round"
            )}
            disabled={true}
            text={expanded ? AddNew : ""}
            iconProps={{ render: (className: string) => <Icons.Addition className={className} /> }}
          />
        </div>
        <NavigationButton
          ariaExpanded={expanded}
          ariaLabel={ExpandCollapseAltLabel}
          className="toggle-navigation flex-align-self-stretch flex-justify-between rounded-8"
          expanded={expanded}
          id="expand"
          onClick={(event) => {
            eventContext.dispatchEvent("telemetryAvailable", { action: "userAction", name: "toggleNavigation", value: !expanded });

            setExpanded(!expanded);
            event.preventDefault();
          }}
          tooltipProps={{ showOnFocus: true, text: ToggleNavigation }}
        >
          {expanded && username && (
            <Tooltip overflowOnly={true}>
              <span className="navigation-user-details flex-row flex-center flex-noshrink font-weight-semibold text-ellipsis">{username}</span>
            </Tooltip>
          )}
          {expanded ? <Icons.NavigationCollapse /> : <Icons.NavigationExpand />}
        </NavigationButton>
        <FocusZone
          direction={FocusZoneDirection.Vertical}
          focusGroupProps={{ defaultElementId: showHome ? "home-page" : "files-page" }}
          handleTabKey={false}
        >
          <div
            aria-label={format("{{username}}, use the up and down keys to move through different sub links; hit enter to navigate to a sub link.", {
              username
            })}
            className={css("flex-column overflow-x-hidden overflow-y-auto margin-bottom-8", !expanded && "scrollbar-hidden")}
            data-testid="navigation-links-group1"
            role="menu"
          >
            {showHome ? (
              <NavigationLink
                ariaLabel={HomePageAltLabel}
                expanded={expanded}
                href="https://onedrive.live.com?qt=mru"
                iconProps={{ render: (className: string) => <Icons.Home className={className} /> }}
                id="home-page"
                onClick={() => eventContext.dispatchEvent("telemetryAvailable", { action: "userAction", name: "navigateHome" })}
                text={HomePage}
              />
            ) : null}
            <NavigationLink
              ariaLabel={FilesPageAltLabel}
              expanded={expanded}
              href="https://onedrive.live.com?qt=root"
              iconProps={{ render: (className: string) => <Icons.Folder className={className} /> }}
              id="files-page"
              onClick={() => eventContext.dispatchEvent("telemetryAvailable", { action: "userAction", name: "navigateMyFiles" })}
              text={FilesPage}
            />
            {showHome ? null : (
              <NavigationLink
                ariaLabel={RecentPageAltLabel}
                expanded={expanded}
                href="https://onedrive.live.com?qt=mru"
                iconProps={{ render: (className: string) => <Icons.Recent className={className} /> }}
                id="recent-page"
                onClick={() => eventContext.dispatchEvent("telemetryAvailable", { action: "userAction", name: "navigateRecent" })}
                text={RecentPage}
              />
            )}
            <NavigationLink
              ariaLabel={PhotosPageAltLabel}
              expanded={expanded}
              href="/"
              iconProps={{ render: (className: string) => <Icons.Photo className={className} /> }}
              id="photos-page"
              onClick={(event) => navigationContext.navigate(event, "navigateGallery")}
              selected={true}
              text={PhotosPage}
            />
            <NavigationLink
              ariaLabel={SharedPageAltLabel}
              expanded={expanded}
              href="https://onedrive.live.com?qt=sharedby"
              iconProps={{ render: (className: string) => <Icons.People className={className} /> }}
              id="shared-page"
              onClick={() => eventContext.dispatchEvent("telemetryAvailable", { action: "userAction", name: "navigateShared" })}
              text={SharedPage}
            />
            <NavigationLink
              ariaLabel={RecycleBinPageAltLabel}
              expanded={expanded}
              href="https://onedrive.live.com?qt=recyclebin"
              iconProps={{ render: (className: string) => <Icons.Recyclebin className={className} /> }}
              id="recycle-bin"
              onClick={() => eventContext.dispatchEvent("telemetryAvailable", { action: "userAction", name: "navigateRecycleBin" })}
              text={RecycleBinPage}
            />
          </div>
        </FocusZone>
        <div className="group-header flex-row">
          {expanded ? (
            <Tooltip overflowOnly={true}>
              <span className="flex-row flex-center flex-noshrink font-weight-semibold text-ellipsis">Browse files by</span>
            </Tooltip>
          ) : (
            <span className="separator-line"></span>
          )}
        </div>
        <FocusZone direction={FocusZoneDirection.Vertical} focusGroupProps={{ defaultElementId: "browse-by-people" }} handleTabKey={false}>
          <div
            className={css("flex-column overflow-x-hidden overflow-y-auto margin-bottom-8", !expanded && "scrollbar-hidden")}
            data-testid="navigation-links-group2"
            role="menu"
          >
            <NavigationLink
              ariaLabel={PeopleAltLabel}
              expanded={expanded}
              href="https://onedrive.live.com?qt=people"
              iconProps={{ render: (className: string) => <Icons.Person className={className} /> }}
              id="browse-by-people"
              onClick={() => eventContext.dispatchEvent("telemetryAvailable", { action: "userAction", name: "navigateBrowseByPeople" })}
              text={PeopleTitle}
            />
          </div>
        </FocusZone>
      </div>
      <div className="flex-column flex-grow flex-justify-end rhythm-vertical-8 padding-top-8">
        <Observer values={{ accountDetails: sessionContext.accountDetails }}>
          {({ accountDetails }) => {
            // If we don't have any account details we don't render anything in this section.
            if (accountDetails && accountDetails.quota) {
              const { quota, unifiedStorageQuota } = accountDetails;
              const planType = getPlanType(accountDetails);
              const { remaining, state, total, used } = unifiedStorageQuota || quota;
              const { storagePlans } = accountDetails.quota;

              // Determine the state of the user based on the account details.
              const full = used >= total;
              const quotaPercentage = Math.floor((used / total) * 100);
              // For free plans, we want nearing state to start at 70% instead of 80%
              const customState = state === "normal" ? (quotaPercentage >= 70 && planType === PlanType.Free ? "nearing" : "normal") : state;

              const warningState = customState !== "normal";

              // Produce the quota message that describes the percentage full.
              const percentageMessage = format(QuotaPercentage, { quotaPercentage });

              return (
                <div
                  className={css(
                    "account-details flex-column",
                    customState === "nearing" && "nearing",
                    customState !== "normal" && customState !== "nearing" && "full",
                    expanded ? "margin-bottom-12 padding-horizontal-12" : "padding-horizontal-4"
                  )}
                >
                  <div
                    className={css(
                      "flex-column padding-top-8 margin-bottom-8",
                      ((customState === "normal" && planType !== PlanType.Premium1TB) || planType === PlanType.Premium100GB) &&
                        expanded &&
                        "purchase-border margin-bottom-16 padding-horizontal-12 padding-top-12 rounded-6"
                    )}
                  >
                    {expanded && !warningState && storagePlans.upgradeAvailable && (
                      <span className="purchase-label flex-row body-s margin-bottom-16">
                        {planType === PlanType.Premium100GB ? PurchaseMoreLabel : PurchaseLabel}
                      </span>
                    )}
                    {warningState ? (
                      customState === "nearing" ? (
                        <NavigationButton
                          className={css(
                            "premium-button nearing-button rounded-6",
                            expanded ? "margin-bottom-12" : "collapsed margin-bottom-4 flex-self-center"
                          )}
                          expanded={expanded}
                          href="https://onedrive.live.com/?v=upgrade&hideLeftNav=true&hideLeftNav=true&ocid=PROD_OneDrive-Web_PremiumLeftNav_Normal_GetMoreStorage"
                          iconProps={{ render: (className: string) => <Icons.WarningFull className={className} /> }}
                          onClick={() => eventContext.dispatchEvent("telemetryAvailable", { action: "userAction", name: "navigatePremium" })}
                          text={BuyMoreStorage}
                        />
                      ) : (
                        <NavigationButton
                          className={css(
                            "premium-button full-button rounded-6",
                            expanded ? "margin-bottom-12" : "collapsed margin-bottom-4 flex-self-center"
                          )}
                          expanded={expanded}
                          href="https://onedrive.live.com/?v=upgrade&hideLeftNav=true&hideLeftNav=true&ocid=PROD_OneDrive-Web_PremiumLeftNav_Normal_GetMoreStorage"
                          iconProps={{ render: (className: string) => <Icons.Error className={className} /> }}
                          onClick={() => {
                            eventContext.dispatchEvent("telemetryAvailable", { action: "userAction", name: "navigatePremium" });
                          }}
                          text={BuyMoreStorage}
                        />
                      )
                    ) : (
                      <NavigationButton
                        className={css("premium-button rounded-6", expanded ? "margin-bottom-12" : "collapsed margin-bottom-4 flex-self-center")}
                        expanded={expanded}
                        href="https://onedrive.live.com/?v=upgrade&hideLeftNav=true&hideLeftNav=true&ocid=PROD_OneDrive-Web_PremiumLeftNav_Normal_GetMoreStorage"
                        iconProps={{ render: (className: string) => <Icons.Diamond className={className} /> }}
                        onClick={() => eventContext.dispatchEvent("telemetryAvailable", { action: "userAction", name: "navigatePremium" })}
                        text={planType === PlanType.Premium100GB ? BuyMoreStorage : planType === PlanType.Premium1TB ? PremiumOneDrive : BuyStorage}
                      />
                    )}
                  </div>

                  {expanded && (
                    <div className="quota-icon-label-container flex-row flex-align-center">
                      <span className="quota-icon-label font-weight-semibold">{StorageLabel}</span>
                    </div>
                  )}
                  <div
                    aria-label={percentageMessage}
                    className={css("flex-row margin-vertical-4", !expanded && "margin-horizontal-8")}
                    role="contentinfo"
                  >
                    <div className="quota-used-percentile flex-noshrink" style={{ width: Math.max(1, Math.min(100, quotaPercentage)) + "%" }} />
                    <div className="quota-unused-percentile flex-grow" />
                  </div>
                  {expanded && (
                    <span className={css("body-s secondary-text white-space-pre-wrap overflow-hidden", warningState && "margin-bottom-8")}>
                      {formatComponent(
                        format(QuotaUsageOver, {
                          overage: formatSize(used - total),
                          quotaPercentage,
                          remaining: formatSize(remaining),
                          total: formatQuotaSize(total),
                          used: formatQuotaSize(used)
                        }),
                        (content) => (
                          <Link
                            className={css("quota-used-text focus-treatment flex-row flex-align-center", full && "underline")}
                            href="https://onedrive.live.com/?v=managestorage"
                            key="manage-storage"
                            onClick={() => eventContext.dispatchEvent("telemetryAvailable", { action: "userAction", name: "manageStorage" })}
                          >
                            {customState === "nearing" && <Icons.WarningFull className="margin-right-4" />}
                            {customState !== "normal" && customState !== "nearing" && <Icons.Error className="margin-right-4" />}
                            {content}
                          </Link>
                        )
                      )}
                    </span>
                  )}
                </div>
              );
            }

            return null;
          }}
        </Observer>
      </div>
    </nav>
  );
}

export interface INavigationPanelProps {
  onDismiss: () => void;
}

export function NavigationPanel(props: INavigationPanelProps): React.ReactElement {
  const { onDismiss } = props;

  // If navigation occurs we need to dismiss the navigation.
  const history = useHistory();

  // We need to dismiss the dialog if we navigate.
  React.useEffect(() => {
    const unregisterCallback = history.listen(() => onDismiss());
    return () => {
      unregisterCallback();
    };
  });

  return (
    <Callout
      className="navigation-callout absolute-fill"
      contentClassName="flex-column"
      contentJustification={ContentJustification.Stretch}
      contentLocation={ContentLocation.Start}
      contentOrientation={ContentOrientation.Column}
      focuszoneProps={{
        circularNavigation: true,
        focusOnMount: true,
        handleTabKey: true,
        includeDefaults: true
      }}
      escDismiss={true}
      id="navigation-panel"
      lightDismiss={true}
      modal={true}
      onDismiss={onDismiss}
    >
      <Navigation expanded={true} setExpanded={onDismiss} />
    </Callout>
  );
}

export interface INavigationButtonProps extends IButtonProps {
  expanded?: boolean;
  selected?: boolean;
}

export function NavigationButton(props: INavigationButtonProps & { children?: React.ReactNode }): React.ReactElement {
  const { ariaLabel, children, className, expanded, selected, text, ...buttonProps } = props;

  return (
    <Button
      ariaLabel={ariaLabel || (!expanded ? text : undefined)}
      className={css("navigation-button flex-noshrink", className, expanded && "expanded", selected && "selected")}
      subtle={true}
      {...buttonProps}
    >
      {expanded && text ? (
        <Tooltip overflowOnly={true}>
          <span className="navigation-button-text text-ellipsis">{text}</span>
        </Tooltip>
      ) : null}
      {children}
    </Button>
  );
}

export interface INavigationLinkProps extends IButtonProps {
  expanded?: boolean;
  selected?: boolean;
}

export function NavigationLink(props: INavigationLinkProps): React.ReactElement {
  const { ariaLabel, className, expanded, iconProps, selected, text, ...buttonProps } = props;

  // Since this doesnt seem to be an iconOnly button when collapsed we will add
  // the tooltip configuration manually.
  const tooltipProps: ITooltipProps | undefined = !expanded ? { text } : undefined;

  return (
    <Button
      ariaLabel={ariaLabel}
      className={css(
        "navigation-link",
        className,
        "navigation-item flex-justify-start flex-row flex-noshrink rounded-8",
        expanded && "expanded",
        selected && "selected"
      )}
      role="menuitem"
      subtle={true}
      tooltipProps={tooltipProps}
      {...buttonProps}
    >
      <div className="selection-banner margin-vertical-4" />
      {iconProps && <Icon {...iconProps} className={css(iconProps.className, "left-icon")} />}
      {expanded && text ? (
        <Tooltip overflowOnly={true}>
          <span className="navigation-link-text text-ellipsis">{text}</span>
        </Tooltip>
      ) : null}
    </Button>
  );
}
