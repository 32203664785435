import { IAccount } from "../types/account";

export const enum PlanType {
  Free = "Free",
  Premium100GB = "Premium100GB",
  Premium1TB = "Premium1TB"
}

const gigabyte = 1024 * 1024 * 1024;

export function getPlanType(accountDetails: IAccount): PlanType | null {
  const { unifiedStorageQuota, quota } = accountDetails;
  if (quota) {
    const { total } = unifiedStorageQuota || quota;
    const { storagePlans } = quota;

    if (total >= 1024 * gigabyte) {
      return PlanType.Premium1TB;
    }

    if (total >= 100 * gigabyte) {
      return PlanType.Premium100GB;
    }

    if (storagePlans.upgradeAvailable) {
      return PlanType.Free;
    }
  }

  return null;
}
