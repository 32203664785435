import { IButtonProps } from "azure-devops-ui/Button";
import { MessageCard } from "azure-devops-ui/MessageCard";
import { css } from "azure-devops-ui/Util";
import React from "react";
import { EventContext } from "../../../common/contexts/event";
import { FeatureContext } from "../../../common/contexts/feature";
import { SettingsContext } from "../../../common/contexts/settings";
import { useSubscription } from "../../../common/hooks/useobservable";
import { format } from "../../../common/utilities/format";
import { IEventDispatch } from "../../../common/utilities/platformdispatch";
import * as Icons from "../../components/illustration/icons";
import { SessionContext } from "../../contexts/session";
import { UserPreferencesContext } from "../../contexts/userpreferences";
import { IAccount } from "../../types/account";
import { IEmailPreferences } from "../../types/userpreferences";
import { formatSize } from "../../utilities/format";
import { getPlanType, PlanType } from "../../utilities/plantype";

import "./notificationgroup.css";

const { BuyStorageButton, ManageStorageButton, UpgradeNowButton } = window.Resources.Common;
const {
  MOJEmailButton,
  MOJEmailText,
  QuotaCritical,
  QuotaFull,
  QuotaFullOver,
  QuotaHalfway,
  QuotaHalfway2,
  QuotaNearing,
  QuotaNearingCritical,
  QuotaOver
} = window.Resources.NotificationBanner;

export interface INotificationGroupProps {
  /**
   * Optional css className added to the root element of the notification group.
   */
  className?: string;
  /**
   * Maximum number of messages that are shown at a given time.
   *
   * @default 1
   */
  maxVisible?: number;
}

export function NotificationGroup(props: INotificationGroupProps): React.ReactElement | null {
  const { className, maxVisible = 1 } = props;

  const eventContext = React.useContext(EventContext);
  const featureContext = React.useContext(FeatureContext);
  const sessionContext = React.useContext(SessionContext);
  const settingsContext = React.useContext(SettingsContext);
  const userPreferencesContext = React.useContext(UserPreferencesContext);

  const [activeBanners, setActiveBanners] = React.useState<IBanner[]>([]);

  useSubscription(sessionContext.accountDetails, processBanners);
  useSubscription(userPreferencesContext.getUserPreferences("email"), processBanners);

  React.useEffect(() => {
    if (activeBanners.length === 0) {
      processBanners();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionContext.accountDetails.value, userPreferencesContext.getUserPreferences("email").value]);

  return (
    <div className={css("photo-notification-group flex-column", className)}>
      {activeBanners.map((banner: IBanner) => {
        return (
          <MessageCard
            buttonProps={banner.buttonProps}
            className={banner.className}
            iconProps={{ render: () => banner.icon }}
            key={banner.type}
            messageBarClassName="notification-banner"
            onDismiss={() => {
              // Set the time to expire in the localStorage
              // if timeout is not provided, set the value to 0 indicating it only shows up once and never expires.
              settingsContext.mergeSetting("notificationsExpiry", {
                [banner.type]: banner.timeout ? Date.now() + banner.timeout * 1000 * 60 * 60 * 24 : 0
              });

              // Remove from the activeBanners to update the UI
              const messageIndex = activeBanners.indexOf(banner);
              if (messageIndex >= 0) {
                const newActiveBanners = activeBanners.filter((_, index) => index !== messageIndex);
                setActiveBanners(newActiveBanners);
              }

              eventContext.dispatchEvent("telemetryAvailable", { action: "userAction", name: "dismissNotificationBanner", value: banner });
              banner.onDismiss?.();
            }}
          >
            {banner.textContent}
          </MessageCard>
        );
      })}
    </div>
  );

  function processBanners() {
    const accountDetails = sessionContext.accountDetails.value;
    const emailPreferences = userPreferencesContext.getUserPreferences("email").value;

    // Don't start retrieving the banners until all the needed details are available for all banner types
    if (accountDetails && emailPreferences) {
      const banners = getBanners({ eventContext, accountDetails, emailPreferences });
      const notificationsExpiry = settingsContext.getSetting<{ [key: string]: number }>("notificationsExpiry") || {};
      let hasSettingExpired = false;
      const tempBanners: IBanner[] = [];

      for (let i = 0; i < banners.length; i++) {
        const current = banners[i];
        const bannerType = current.type;

        if (
          current.featureDependencies &&
          current.featureDependencies.some((feature: string) => featureContext.featureEnabled(feature).value === false)
        ) {
          continue;
        }

        // Check if the banner was dimissed by user and has an expiry time
        const expiration = notificationsExpiry[bannerType];
        if (expiration === 0 || Date.now() < expiration) {
          // When this banner only shows up once, and it is not expired yet, keep hiding this banner and continue on to the next banner
          continue;
        }

        // Expired, remove the config from the localstorage
        hasSettingExpired = true;
        delete notificationsExpiry[bannerType];

        // If we reached the max allowed notification, stop looping the rest of the banners
        if (tempBanners.length < maxVisible) {
          // Add the banner to the available list
          tempBanners.push(current);
        } else {
          break;
        }
      }

      setActiveBanners(tempBanners);

      // Update localStorage if there are expired settings
      if (hasSettingExpired) {
        settingsContext.setSetting("notificationsExpiry", notificationsExpiry);
      }
    }
  }
}

interface IGetBanners {
  accountDetails: IAccount; //for quota related banners
  emailPreferences: IEmailPreferences; //for email related banners
  eventContext: IEventDispatch;
}

interface IBanner {
  className: string;
  buttonProps: IButtonProps[] | undefined;
  featureDependencies?: string[];
  icon: React.ReactElement;
  onDismiss?: () => void;
  textContent: React.ReactElement | string;
  timeout: number;
  type: "mojemail" | "quota";
}

function getBanners(props: IGetBanners): IBanner[] {
  const { accountDetails, emailPreferences, eventContext } = props;

  const banners: IBanner[] = [];

  // If we don't have any account details we don't add the banner to the list
  if (accountDetails && accountDetails.quota) {
    const { quota, unifiedStorageQuota } = accountDetails;
    const { state, total, used } = unifiedStorageQuota || quota;
    const { storagePlans } = quota;

    const planType = getPlanType(accountDetails);
    const premium100GB = planType === PlanType.Premium100GB;
    const quotaPercentage = Math.floor((used / total) * 100);

    const criticalQuota = state === "critical";
    const fullQuota = state === "full";
    const nearingQuota = state === "nearing" || (state === "normal" && quotaPercentage >= 70 && storagePlans.upgradeAvailable && !premium100GB);
    const overHalfwayQuota = state === "normal" && quotaPercentage >= 50 && quotaPercentage <= 69 && storagePlans.upgradeAvailable && !premium100GB;
    const overQuota = state === "overlimit" || state === "exceeded";

    const quotaButtons = [
      {
        className: "storage-link",
        href: "https://onedrive.live.com/?v=managestorage",
        onClick: () => {
          eventContext.dispatchEvent("telemetryAvailable", { action: "userAction", name: "manageStorage" });
        },
        subtle: true,
        text: ManageStorageButton
      },
      {
        className: "upgrade-button",
        href: "https://onedrive.live.com/?v=upgrade&hideLeftNav=true&hideLeftNav=true&ocid=PROD_OneDrive-Web_PremiumLeftNav_Normal_GetMoreStorage",
        onClick: () => {
          eventContext.dispatchEvent("telemetryAvailable", { action: "userAction", name: "navigatePremium" });
        },
        text: overHalfwayQuota ? UpgradeNowButton : BuyStorageButton
      }
    ];

    if (overHalfwayQuota) {
      banners.push({
        className: "banner quota-banner halfway",
        buttonProps: quotaButtons,
        icon: <Icons.Information className="margin-right-8" />,
        textContent: (
          <>
            <span className="font-weight-semibold body-s margin-right-12">{QuotaHalfway}</span>
            <span className="body-s">{format(QuotaHalfway2, { size: formatSize(used) })}</span>
          </>
        ),
        timeout: 90,
        type: "quota"
      });
    } else if (nearingQuota) {
      banners.push({
        className: "banner quota-banner full",
        buttonProps: quotaButtons,
        icon: <Icons.Warning className="margin-right-8" />,
        textContent: (
          <>
            <span className="font-weight-semibold body-s margin-right-12">{format(QuotaNearing, { percentage: quotaPercentage })}</span>
            <span className="body-s">{QuotaNearingCritical}</span>
          </>
        ),
        timeout: 90,
        type: "quota"
      });
    } else if (criticalQuota) {
      banners.push({
        className: "banner quota-banner full",
        buttonProps: quotaButtons,
        icon: <Icons.Error className="margin-right-8" />,
        textContent: (
          <>
            <span className="font-weight-semibold body-s margin-right-12">{format(QuotaCritical, { percentage: quotaPercentage })}</span>
            <span className="body-s">{QuotaNearingCritical}</span>
          </>
        ),
        timeout: 90,
        type: "quota"
      });
    } else if (fullQuota) {
      banners.push({
        className: "banner quota-banner full",
        buttonProps: quotaButtons,
        icon: <Icons.Error className="margin-right-8" />,
        textContent: (
          <>
            <span className="font-weight-semibold body-s margin-right-12">{format(QuotaFull, { percentage: quotaPercentage })}</span>
            <span className="body-s">{QuotaFullOver}</span>
          </>
        ),
        timeout: 90,
        type: "quota"
      });
    } else if (overQuota) {
      banners.push({
        className: "banner quota-banner full",
        buttonProps: quotaButtons,
        icon: <Icons.Error className="margin-right-8" />,
        textContent: (
          <>
            <span className="font-weight-semibold body-s margin-right-12">{format(QuotaOver, { percentage: quotaPercentage })}</span>
            <span className="body-s">{QuotaFullOver}</span>
          </>
        ),
        timeout: 90,
        type: "quota"
      });
    }
  }

  // If we don't have any email preferences we don't add the banner to the list
  if (emailPreferences) {
    // The email preferences contains the on/off status for email notifications.
    // WeekendRecap(onedrive) or albumSuggestion(mpc-migrated) is to determine if onthisday email setting is on or not.
    if (!(emailPreferences.WeekendRecap || emailPreferences.albumSuggestion)) {
      banners.push({
        className: "banner moj-banner",
        buttonProps: [
          {
            text: MOJEmailButton,
            href: "https://onedrive.live.com/?v=NotificationOptions",
            onClick: () => {
              eventContext.dispatchEvent("telemetryAvailable", { action: "userAction", name: "goToNotificationSettingPage" });
            },
            primary: true
          }
        ],
        featureDependencies: ["EnableMOJNotificationBanner"],
        icon: <Icons.StarHighlighted className="margin-right-8" />,
        textContent: MOJEmailText,
        timeout: 90,
        type: "mojemail"
      });
    }
  }

  return banners;
}
