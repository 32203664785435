import { FocusZone, FocusZoneDirection, IFocusZoneProps } from "azure-devops-ui/FocusZone";
import { css } from "azure-devops-ui/Util";
import React from "react";
import { Observer } from "../../../common/components/observer/observer";
import { useDragRect } from "../../../common/hooks/usedragrect";
import { nonPassiveOptions, passiveOptions, useEventListener } from "../../../common/hooks/uselistener";
import { useSubscription, useSubscriptionArray } from "../../../common/hooks/useobservable";
import { usePromise } from "../../../common/hooks/usepromise";
import { useResize } from "../../../common/hooks/useresize";
import { useTimeout } from "../../../common/hooks/usetimeout";
import { ISelection } from "../../../common/utilities/selection";
import { useRejection } from "../../hooks/userejection";
import { IDimensions } from "../../types/item";
import { IFeedPage, IItemFeed } from "../../types/itemfeed";
import { ILayout, ILayoutElement, IRectangle, ITileDetails, LayoutFunction } from "../../types/layout";

import "./layout.css";

const virtualizationSpacing = 1500;
const pageLoadSpacing = 1500;

const defaultFocuszoneProps: IFocusZoneProps = {
  direction: FocusZoneDirection.Vertical,
  handleTabKey: false,
  skipHiddenCheck: true
};

/**
 * Each item being rendered in the Layout component must implement the
 * IItemForLayout interface.
 */
export interface IItemForLayout {
  /**
   * dimensions of the item being laid out.
   */
  dimensions: IDimensions;

  /**
   * Unique id for the item within the layout.
   */
  id: string;

  /**
   * Whether or not the item is selectable.
   *
   * @default true
   */
  selectable?: boolean;
}

/**
 * ILayoutProps are used to configure the Layout component.
 */
export interface ILayoutProps<T extends { id: string }> {
  /**
   * Aria label that describes that content of the layout.
   */
  ariaLabel?: string;

  /**
   * Optional css className to be included in the root element of the layout.
   */
  className?: string;

  /**
   * The Layout component offers an API through the componentRef prop. If the
   * caller wishes to access this API it should supply a reference.
   */
  componentRef?: React.MutableRefObject<ILayout<T> | undefined>;

  /**
   * Should the layout support drag selection or not.
   *
   * @default true
   */
  dragSelect?: boolean;

  /**
   * Properties that are used to customize the focuszone that surrounds the
   * tiles within the layout.
   */
  focuszoneProps?: IFocusZoneProps;

  /**
   * The caller supplies an observable array of items used to render the layout.
   * The caller is responsible for managing the contents of this array as
   * the layout requests new pages.
   */
  itemFeed: IItemFeed<T>;

  /**
   * The layoutFunction used to generate the layout details.
   */
  layoutFunction: LayoutFunction<T>;

  /**
   * Allows a custom aria-role to be applied to the layout.
   *
   * @default list
   */
  role?: string;

  /**
   * You can pass a selection to the layout and the layout may offer additional
   * selection capabilities, like dragSelect.
   */
  selection?: ISelection;

  /**
   * The caller must supply the container element that is used to scroll the view.
   * This gives the parent component the ability and responsibility to control how
   * the scrolling element presents the items.
   */
  scrollingElement: React.RefObject<HTMLElement>;

  /**
   * The viewUpdated function is called each time the view changes, this happens
   * when the layout is scrolled. The current state of the viewport is past to the
   * callback.
   *
   * @param renderedElements This is the set of all elements rendered into the
   * layout. This includes placeholders, headers, and any other element that is
   * generated.
   *
   * @param renderedTiles This is the set of tiles that were rendered into
   * the view on the last pass.
   */
  viewUpdated?: (renderedElements: ILayoutElement<T>[], renderedTiles: ITileDetails<T>[]) => void;
}

/**
 * Layout is the React component used to render a dynamic layout of items.
 *
 * The caller supplies a layout algorithm and the layout engine manages the
 * elements rendered through the delegate. This allows callers to use the
 * Layout component to achieve multiple layouts simply.
 */
export function Layout<T extends IItemForLayout>(props: ILayoutProps<T>): React.ReactElement | null {
  const {
    ariaLabel,
    className,
    componentRef,
    dragSelect = true,
    focuszoneProps = defaultFocuszoneProps,
    itemFeed,
    layoutFunction,
    role = "list",
    selection,
    scrollingElement,
    viewUpdated
  } = props;
  const { cursor, getItemsFromFeed } = itemFeed;

  const failureCount = React.useRef(0);
  const focusedItems = React.useRef<T[]>([]);
  const layoutElement = React.useRef<HTMLDivElement>(null);
  const pivotItem = React.useRef<T>();
  const pivotItemRect = React.useRef<IRectangle>();
  const prerangeSelection = React.useRef<string[]>([]);
  const updateInProgress = React.useRef(false);

  const [items, setItems] = React.useState<{ value: T[] }>({ value: [] });
  const [, setCount] = React.useState(0);
  const [viewportWidth, setViewportWidth] = React.useState(0);

  // Expose the public API of the layout component.
  React.useImperativeHandle(componentRef, () => ({ getCenterTile, getTile, getAllTiles, getVisibleTiles, scrollTo }));

  // Use a drag rectangle to perform selection
  const { dragRect, onMouseDown, onTouchStart } = useDragRect({ minDistance: 15 });

  // Timeout used to throttle scrolling evaluations.
  const { setTimeout: setResizeTimeout } = useTimeout();
  const { setTimeout: setScrollTimeout } = useTimeout({ preserve: true });
  const { setTimeout: setSelectionTimeout } = useTimeout({ preserve: true });
  const { trackPromise } = usePromise();

  const handleRejection = useRejection();
  const initialized = useSubscription<boolean>(itemFeed.initialized);

  const { elements, height, tiles } = React.useMemo(() => {
    if (viewportWidth) {
      return layoutFunction(
        items.value,
        viewportWidth,
        cursor.previousPage ? pivotItem.current : undefined,
        !!cursor.previousPage || !initialized,
        !!cursor.nextPage
      );
    } else {
      return { elements: [], height: 0, tiles: [] };
    }
  }, [cursor.previousPage, cursor.nextPage, items, initialized, layoutFunction, viewportWidth]);

  // When the caller passes in a new function or set of items we will start over
  // and load new items based on the new values.
  React.useMemo(() => {
    pivotItem.current = undefined;
    pivotItemRect.current = undefined;
    focusedItems.current = [];

    getItemsFromFeed().catch(handleRejection);
  }, [getItemsFromFeed, handleRejection]);

  // Determine the current visible size of the layout within the viewport.
  // Filter the set of elements to reduce the number rendered at any given time;
  const filteredElements: React.ReactElement<T>[] = [];
  const renderedElements: ILayoutElement<T>[] = [];
  const renderedTiles: ITileDetails<T>[] = [];

  if (scrollingElement.current && layoutElement.current) {
    const viewportElementRect = scrollingElement.current.getBoundingClientRect();
    const layoutElementRect = layoutElement.current.getBoundingClientRect();

    // Compute the top and bottom of the viewport, add the padding to both ends
    // to keep the "near" elements pre-rendered.
    const virtualTop = Math.max(0, -(layoutElementRect.top - viewportElementRect.top)) - virtualizationSpacing;
    const virtualHeight = viewportElementRect.height - Math.max(0, layoutElementRect.top - viewportElementRect.top) + 2 * virtualizationSpacing;

    for (let elementIndex = 0; elementIndex < elements.length; elementIndex++) {
      const element = elements[elementIndex];

      // When paging through previous pages we need to ensure we have enough items
      // rendered that we cover the shift when placeholders are added to the top.
      // This means we need the viewport to cover the shift based on the height
      // of the placeholders (the first tile).
      if (element.rect.top < virtualTop + virtualHeight + (tiles[0] ? tiles[0].rect.top : 0) && element.rect.top + element.rect.height > virtualTop) {
        filteredElements.push(element.element);
        renderedElements.push(element);

        if (element.tileDetails) {
          renderedTiles.push(element.tileDetails);
        }

        continue;
      }

      // Render elements that are defined as focus elements. These should not be
      // removed from the rendered view.
      if (focusedItems.current && element.tileDetails && focusedItems.current.indexOf(element.tileDetails.item) >= 0) {
        filteredElements.push(element.element);
      }
    }
  }

  // Compute the default element id for the first tile.
  const defaultElementId = renderedTiles.length ? renderedTiles[0].item.id : "";

  // After the layout has updated we need to make sure we are scrolled to the
  // proper location. If we just loaded a previous page we need to account for
  // the tile shifts.
  React.useLayoutEffect(() => {
    // If we have a pivotItem we should make sure it is scrolled to the proper
    // location based on the change from the last render of the item.
    if (scrollingElement.current && pivotItem.current) {
      const tile = getTile(pivotItem.current);

      if (tile) {
        if (pivotItemRect.current) {
          scrollingElement.current.scrollTop += tile.rect.top - pivotItemRect.current.top;
        } else if (cursor.previousPage) {
          scrollTo(tile.item, "center");
        }

        pivotItemRect.current = tile.rect;
      }
    }

    // Notify the caller about the view updates.
    viewUpdated && viewUpdated(renderedElements, renderedTiles);

    // Now that the client has had a chance to update the view we will evaluate
    // the current viewport to determine if new pages should be loaded.
    evaluateViewport();
  });

  // We will subscribe to the items and re-render when they are changed.
  // We use count instead of just letting the observable update state so we
  // can use this value to re-compute the layout.
  useSubscriptionArray(itemFeed.items, (changes) => {
    const { addedItems, removedItems } = changes;

    if (addedItems) {
      // If this is the first time items are added and we don't have a pivot item
      // we will track the first item as the pivot item.
      if (!pivotItem.current) {
        pivotItem.current = addedItems[0];
      }
    }

    // If this pivotItem is deleted, we need to reset it to the next available.
    if (removedItems && pivotItem.current) {
      for (let index = 0; index < removedItems.length; index++) {
        const removedItem = removedItems[index];

        if (removedItem.id === pivotItem.current.id) {
          pivotItem.current = itemFeed.items.value[Math.max(0, changes.index - 1)];
          pivotItemRect.current = undefined;
          break;
        }
      }
    }

    setItems({ value: itemFeed.items.value });
  });

  // Handle the scroll event to process virtualization when we need too.
  useEventListener(
    scrollingElement.current,
    "scroll",
    () => {
      // Reset the failure count when the user performs a scrolling action to
      // attempt more network retries.
      failureCount.current = 0;

      // The most common form of scrolling is to use the mousewheel and this produces
      // a large number of mini scroll events in a short period of time. To prevent
      // us from over using CPU and causing jank we will only update a maximum of
      // every 30ms.
      setScrollTimeout(() => setCount((count) => count + 1), 30);
    },
    passiveOptions
  );

  // Add an active touchmove event handler that will prevent default if a
  // dragRect is currently active. This prevents the default scrolling
  // which makes vertical selection impossible.
  useEventListener(
    scrollingElement.current,
    "touchmove",
    (event: TouchEvent) => {
      if (dragRect.value) {
        event.preventDefault();
      }
    },
    nonPassiveOptions
  );

  // When the containing element changes size we need to render the layout.
  useResize(layoutElement, (entries: ResizeObserverEntry[]) => {
    setResizeTimeout(() => {
      if (viewportWidth !== entries[0].contentRect.width) {
        setViewportWidth(entries[0].contentRect.width);
      }
    }, 50);
  });

  return (
    <FocusZone {...focuszoneProps} focusGroupProps={{ defaultElementId }}>
      <div
        aria-label={ariaLabel}
        className={css("tile-layout", className, "relative flex-noshrink user-select-none")}
        onFocus={onFocus}
        onMouseDown={(event) => {
          if (dragSelect) {
            if (!event.defaultPrevented && selection) {
              // If we are holding the Shift key when we start the mouse movement
              // we will save the current selection to merge with it.
              if (event.shiftKey) {
                prerangeSelection.current = Array.from(selection.value);
              } else {
                prerangeSelection.current = [];
              }
            }

            onMouseDown(event);
          }
        }}
        onTouchStart={(event: React.TouchEvent) => {
          if (dragSelect) {
            onTouchStart(event);

            if (!event.defaultPrevented && selection) {
              // Save the current selection to merge with it.
              prerangeSelection.current = Array.from(selection.value);
            }
          }
        }}
        ref={layoutElement}
        role={role}
        style={{ height }}
      >
        {filteredElements}
        <Observer values={{ dragRect }}>
          {({ dragRect }) => {
            if (dragRect && selection) {
              // Every 25ms we will update the selected range for the photos
              // that are in the drag rectangle.
              setSelectionTimeout(() => {
                const selectedItems: string[] = [];

                // Go through each tile to determine if it is intersecting.
                for (const tile of tiles) {
                  if (intersectRect(dragRect, tile.rect) && tile.item.selectable !== false) {
                    selectedItems.push(tile.item.id);
                  }
                }

                selection && selection.select([...prerangeSelection.current, ...selectedItems]);
              }, 25);

              return (
                <div aria-hidden={true} className="absolute-fill pointer-events-none">
                  <div className="layout-range-select relative" style={dragRect} />
                </div>
              );
            } else {
              return null;
            }
          }}
        </Observer>
      </div>
    </FocusZone>
  );

  /**
   * evaluateViewport is used to detect changes in the visibility of placeholders
   * and item pages. This function will update the pages with the pages that should
   * be rendered along with the underlying array of items being laid out.
   */
  function evaluateViewport(): void {
    const _scrollingElement = scrollingElement.current;

    // Once we have items rendered and we will look to see if more pages are needed.
    // NOTE: We will not continually retry on paging failures, we will only try
    // 3 times until the user tries to scroll.
    if (_scrollingElement && initialized && tiles.length && failureCount.current < 2) {
      // Since the evaluation occurs asynchronously we need to make sure we dont start
      // multiple evaluations at the same time.
      if (!updateInProgress.current) {
        const pageRequests: [Promise<IFeedPage<T>> | undefined, Promise<IFeedPage<T>> | undefined] = [undefined, undefined];

        updateInProgress.current = true;

        // Determine if their are previous pages and our first page is within
        // "near" visible range of the viewport.
        if (cursor.previousPage && tiles[0].rect.top + pageLoadSpacing >= _scrollingElement.scrollTop) {
          pageRequests[0] = getItemsFromFeed(cursor.previousPage);
        }

        // Determine if their are next pages and our last page is within
        // "near" visible range of the viewport.
        if (
          cursor.nextPage &&
          tiles[tiles.length - 1].rect.top + tiles[tiles.length - 1].rect.height <=
            _scrollingElement.scrollTop + _scrollingElement.clientHeight + pageLoadSpacing
        ) {
          pageRequests[1] = getItemsFromFeed(cursor.nextPage);
        }

        if (pageRequests[0] || pageRequests[1]) {
          trackPromise(Promise.all(pageRequests))
            .then(() => {
              updateInProgress.current = false;
              failureCount.current = 0;
              setCount((count) => count + 1);
            })
            .catch((error) => {
              if (!error.canceled) {
                updateInProgress.current = false;
                setCount((count) => count + 1);

                // If this is the first failure we will let the user know we are
                // failing to get more photos.
                if (++failureCount.current === 1) {
                  handleRejection(error);
                }
              }
            });
        } else {
          updateInProgress.current = false;
        }
      }
    }
  }

  /**
   * getAllTiles returns all tiles laid out by the layout component.
   *
   * @returns array of ITileDetails objects that have been laid out.
   */
  function getAllTiles(): ITileDetails<T>[] {
    return tiles;
  }

  function getCenterTile(): ITileDetails<T> | undefined {
    const _layoutElement = layoutElement.current;
    const _scrollingElement = scrollingElement.current;
    let centerTile: ITileDetails<T> | undefined;

    if (_layoutElement && _scrollingElement) {
      const layoutRect = _layoutElement.getBoundingClientRect();
      const scrollingRect = _scrollingElement.getBoundingClientRect();
      const layoutOffsetX = layoutRect.left - scrollingRect.left + _scrollingElement.scrollLeft;
      const layoutOffsetY = layoutRect.top - scrollingRect.top + _scrollingElement.scrollTop;
      let distanceFromCenter = Number.MAX_SAFE_INTEGER;

      const centerPoint = {
        x: (scrollingRect.right - scrollingRect.left) / 2 + _scrollingElement.scrollLeft,
        y: (scrollingRect.bottom - scrollingRect.top) / 2 + _scrollingElement.scrollTop
      };

      // Compute the distance to center for each visible tile, track the closest tile.
      for (let tileIndex = 0; tileIndex < tiles.length; tileIndex++) {
        const tile = tiles[tileIndex];
        const tileRect = tile.rect;

        const distanceX = Math.abs(centerPoint.x - (tileRect.left + layoutOffsetX + tileRect.width / 2));
        const distanceY = Math.abs(centerPoint.y - (tileRect.top + layoutOffsetY + tileRect.height / 2));
        const distance = Math.sqrt(distanceX * distanceX + distanceY * distanceY);

        if (distance < distanceFromCenter) {
          distanceFromCenter = distance;
          centerTile = tile;
        }
      }
    }

    return centerTile;
  }

  /**
   * Get the element that contains the given item.
   *
   * @param item The underlying item for this tile.
   * @returns the appropriate tile if the item is in the layout, otherwise undefined.
   */
  function getTile(item: T): ITileDetails<T> | undefined {
    for (let tile of tiles) {
      if (tile.item === item || (tile.group.length > 1 && tile.group.indexOf(item) >= 0)) {
        return tile;
      }
    }

    return undefined;
  }

  /**
   * getVisibleTiles is used to retrieve the set of tiles that are currently
   * visible in the layout.
   *
   * @returns array of ITileDetails objects that intersect the visible viewport.
   */
  function getVisibleTiles(): ITileDetails<T>[] {
    const _layoutElement = layoutElement.current;
    const _scrollingElement = scrollingElement.current;
    const intersectingTiles: ITileDetails<T>[] = [];

    if (_layoutElement && _scrollingElement) {
      const layoutRect = _layoutElement.getBoundingClientRect();
      const viewportRect = _scrollingElement.getBoundingClientRect();

      // Normalize the rectangle to the parent rectangle coordinates.
      const left = layoutRect.left - viewportRect.left;
      const top = layoutRect.top - viewportRect.top;

      // Compute the intersecting rectangle relative to the layout rectangle.
      const rect = {
        left: left > 0 ? 0 : Math.abs(left),
        height: Math.min(layoutRect.height, viewportRect.height - Math.max(0, top)),
        top: top > 0 ? 0 : Math.abs(top),
        width: Math.min(layoutRect.width, viewportRect.width - Math.max(0, left))
      };

      // Go through each tile to determine if it is intersecting.
      for (const tile of tiles) {
        if (intersectRect(rect, tile.rect)) {
          intersectingTiles.push(tile);
        }
      }
    }

    return intersectingTiles;
  }

  function onFocus(event: React.FocusEvent): void {
    let currentElement: Element | null = event.target;

    // Determine which element has focus and add it, and the next and previous
    // elements. This will allow keyboard navigation to continue to work even
    // when the focused element scrolls out of the rendered viewport.
    while (currentElement && currentElement !== layoutElement.current) {
      const focusedId = currentElement.getAttribute("data-item-id");
      if (focusedId) {
        for (let tileIndex = 0; tileIndex < tiles.length; tileIndex++) {
          const { id } = tiles[tileIndex].item;

          if (focusedId === id) {
            focusedItems.current.push(tiles[tileIndex].item);

            // Get the previous element that contains an id.
            if (tileIndex > 0) {
              focusedItems.current.push(tiles[tileIndex - 1].item);
            }

            // Get the next element that contains an id
            if (tileIndex + 1 < tiles.length) {
              focusedItems.current.push(tiles[tileIndex + 1].item);
            }

            break;
          }
        }

        break;
      }

      currentElement = currentElement.parentElement;
    }
  }

  /**
   * scrollTo can be used to scroll a given tile to a specfic location in the
   * visible viewport.
   *
   * @param item The item that should be scrolled.
   * @param location The location the scroll the tile to.
   */
  function scrollTo(item: T, location: "top" | "center" | "bottom"): void {
    const _layoutElement = layoutElement.current;
    const _scrollingElement = scrollingElement.current;

    if (_scrollingElement && _layoutElement) {
      // Get the tile for the requested scolling item.
      const tile = getTile(item);

      // If the requested tile is still in the rendered, we will scroll it to the
      // target location.
      if (tile) {
        const layoutRect = _layoutElement.getBoundingClientRect();
        const scrollingRect = _scrollingElement.getBoundingClientRect();
        const layoutOffset = layoutRect.top - scrollingRect.top + _scrollingElement.scrollTop;
        const tileRect = tile?.rect;
        let targetLocation: number;

        if (location === "top") {
          targetLocation = tileRect.top;
        } else if (location === "center") {
          targetLocation = tileRect.top - (_scrollingElement.clientHeight - tileRect.height) / 2;
        } else {
          targetLocation = tileRect.top - (scrollingElement.current.clientHeight - tileRect.height);
        }

        // Scroll to the desired location in the scrollingElement.
        scrollingElement.current.scrollTop = Math.max(0, targetLocation + layoutOffset);
      }
    }
  }
}

function intersectRect(rect1: IRectangle, rect2: IRectangle): boolean {
  return (
    rect1.left < rect2.left + rect2.width &&
    rect1.left + rect1.width > rect2.left &&
    rect1.top < rect2.top + rect2.height &&
    rect1.top + rect1.height > rect2.top
  );
}
