import { format, formatURL } from "../../common/utilities/format";
import { IItem } from "../types/item";
import { graphJSON, graphNoContent, graphResponse } from "./network";

interface IBadgerResponse {
  token: string;
}

const sharesApi = "{{vroomOrigin}}/{{apiVersion1}}/shares/u!{{redeem}}/driveitem?{{parameters}}";

export function redeemToken(
  fetch: (url: string, init?: RequestInit) => Promise<Response>,
  redeem: string,
  badgerToken?: string
): Promise<Pick<IItem, "id" | "parentReference">> {
  // If authenticated, use bearer token
  return graphResponse<Pick<IItem, "id" | "parentReference">>(
    fetch(formatURL(sharesApi, { redeem, parameters: new URLSearchParams({ $select: "id,parentReference" }) }), {
      headers: { "Content-Type": "text/plain;charset=UTF-8", Prefer: "autoredeem", Authorization: badgerToken ? `Badger ${badgerToken}` : "" },
      method: badgerToken ? "POST" : "GET"
    }),
    graphJSON
  );
}

const refreshApi = "{{authOrigin}}/auth/refresh?{{parameters}}";

export function refreshToken(fetch: (url: string, init?: RequestInit) => Promise<Response>, scope: string, refresh_token: string): Promise<void> {
  return graphResponse(fetch(formatURL(refreshApi, { parameters: new URLSearchParams({ scope, refresh_token }) })), graphNoContent);
}

const badgerApi = "{{badgerApiOrigin}}/v1.0/token";

export function requestBadgerToken(fetch: (url: string, init?: RequestInit) => Promise<Response>): Promise<string> {
  return graphResponse<IBadgerResponse>(
    fetch(badgerApi, {
      body: JSON.stringify({ appId: "073204aa-c1e0-4e66-a200-e5815a0aa93d" }),
      headers: {
        "Content-Type": "application/json;odata=verbose",
        "Cache-Control": "private"
      },
      method: "POST"
    }),
    graphJSON
  ).then((response) => response.token);
}

const validatePasswordApiUnAuthUsers = "{{vroomOrigin}}/{{apiVersion1}}/shares/{{encodedShareUrl}}/root/action.validatePermission";
const validatePasswordApi = "{{vroomOrigin}}/{{apiVersion1}}/drive/items/{{photoId}}/action.validatePermission";

export function validatePassword(
  fetch: (url: string, init?: RequestInit) => Promise<Response>,
  photoId: string,
  password: string,
  token: string,
  userAuthenticated: boolean,
  badgerToken?: string
): Promise<void> {
  let formattedApiUrl: string;

  if (token && token.charAt(0) === "!") {
    token = token.slice(1);
  }

  if (userAuthenticated) {
    formattedApiUrl = formatURL(validatePasswordApi, { photoId });
  } else {
    const encodedShareUrl = `u!${window
      .btoa(formatURL("https://api.onedrive.com/redir?{{parameters}}", { parameters: new URLSearchParams({ resid: photoId }) }))
      .replace(/=/g, "")
      .replace(/[+]/g, "-")
      .replace(/\//g, "_")}`;

    formattedApiUrl = format(validatePasswordApiUnAuthUsers, { encodedShareUrl });
  }

  const headers: { [key: string]: string } = { "Content-Type": "application/json" };
  if (badgerToken) {
    headers.Authorization = `Badger ${badgerToken}`;
  }

  return graphResponse(
    fetch(formattedApiUrl, {
      body: JSON.stringify({
        challengeToken: token,
        password: password
      }),
      headers,
      method: "POST"
    }),
    (response: Response) => {
      if (response.ok && (response.status === 204 || response.status === 200)) {
        return Promise.resolve();
      } else {
        return Promise.reject(response);
      }
    }
  );
}

const validatePasswordCOBApi = "{{vroomOrigin}}/{{apiVersion2}}/shares/{{encodedShareUrl}}/root/oneDrive.validatePermission";

export function validatePasswordCOB(
  fetch: (url: string, init?: RequestInit) => Promise<Response>,
  password: string,
  token: string,
  badgerToken?: string
): Promise<void> {
  let formattedApiUrl: string;

  if (token && token.charAt(0) === "!") {
    token = token.slice(1);
  }

  formattedApiUrl = formatURL(validatePasswordCOBApi, { encodedShareUrl: `u!${token}` });

  const headers: { [key: string]: string } = { "Content-Type": "application/json" };
  if (badgerToken) {
    headers.Authorization = `Badger ${badgerToken}`;
  }

  return graphResponse(
    fetch(formattedApiUrl, {
      body: JSON.stringify({
        challengeToken: token,
        password: password
      }),
      headers,
      method: "POST"
    }),
    (response: Response) => {
      if (response.ok && (response.status === 204 || response.status === 200)) {
        return Promise.resolve();
      } else {
        return Promise.reject(response);
      }
    }
  );
}
