/**
 * Runs a binary search for a value in the given sorted array.
 * @param arrayValue Array to search.
 * @param value Value to search for/compare against.
 * @param compareFn Function for comparing array values to search value.
 * @returns Index of found value.
 *
 * Note that if a matching value (value for which comparison function returns 0) is not in the array,
 * the index of where that element would be in the array is returned (index between lesser element and greater element).
 */
export function binarySearch<T, V>(arrayValue: T[], value: V, compareFn: (a: T, b: V) => number): number {
  let end = arrayValue.length - 1;
  let start = 0;
  let mid = 0;
  let comparison = 0;

  while (start <= end) {
    mid = Math.floor((start + end) / 2);

    comparison = compareFn(arrayValue[mid], value);

    if (comparison === 0) {
      break;
    }

    if (comparison > 0) {
      start = mid + 1;
    } else {
      end = mid - 1;
    }
  }

  if (comparison > 0) {
    mid++;
  }

  return mid;
}
