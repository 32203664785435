import React from "react";
import { SessionContext } from "../../photos/contexts/session";
import { getPlanType } from "../../photos/utilities/plantype";
import { EventContext } from "../contexts/event";
import { IFetchCompleteEvent } from "../utilities/fetch";
import { defer } from "../utilities/promise";
import { getScenarioEvent, startScenario } from "../utilities/scenario";
import { useEventContextListener } from "./useeventcontextlistener";

/**
 * Hook that logs one "active session" (i.e. when the app is on the foreground).
 * The scenario completes when the document visibility state is toggled to hidden or on component unmount.
 * The scenario may start with the document hidden or visible.
 */
export function useLogEngagementSession(): void {
  const eventContext = React.useContext(EventContext);
  const sessionContext = React.useContext(SessionContext);

  const throttleCountFromQuota = React.useRef(0);
  const throttleCountFromServiceUnavailability = React.useRef(0);

  const [completeScenario] = React.useState(() => {
    const { promise, resolve } = defer<void>();

    document.addEventListener("visibilitychange", handleVisibilityChange);

    const scenario = startScenario(
      promise,
      {
        scenarioName: "engagementSession",
        scenarioType: "engagementSession"
      },
      (scenarioDetails) => {
        const accountDetails = sessionContext.accountDetails.value;
        scenario.log({
          planType: accountDetails ? getPlanType(accountDetails) : undefined,
          quotaThrottleCount: throttleCountFromQuota.current,
          serviceThrottleCount: throttleCountFromServiceUnavailability.current
        });

        const scenarioEvent = getScenarioEvent("scenarioComplete", scenarioDetails);
        eventContext.dispatchEvent("scenarioComplete", scenarioEvent);
        eventContext.dispatchEvent("telemetryAvailable", scenarioEvent);

        document.removeEventListener("visibilitychange", handleVisibilityChange);
        throttleCountFromQuota.current = 0;
        throttleCountFromServiceUnavailability.current = 0;
      }
    );

    eventContext.dispatchEvent("scenarioStart", scenario);

    return resolve;

    function handleVisibilityChange(): void {
      if (document.visibilityState === "hidden") {
        resolve();
      }
    }
  });

  useEventContextListener("fetchComplete", React.useCallback(countThrottles, []));

  React.useEffect(() => {
    return completeScenario;
  }, [completeScenario]);

  function countThrottles(event: IFetchCompleteEvent): void {
    const httpStatus = fromValue(event.telemetryProperties.httpStatus);
    if (httpStatus === 429) {
      throttleCountFromQuota.current++;
    } else if (httpStatus === 503) {
      throttleCountFromServiceUnavailability.current++;
    }
  }

  function fromValue(value: string | number | boolean | undefined): number | undefined {
    return typeof value === "number" ? value : typeof value === "string" ? Number.parseInt(value) : undefined;
  }
}
