import { formatURL } from "../../common/utilities/format";
import { IFolder, IItem, IPermissions, SpecialFolder } from "../types/item";
import { TopLevelScenario } from "../utilities/fetch";
import { graphResponse } from "./network";
import { preparePermissions } from "./util";

export interface IGetItemOptions {
  expand?: string[];
  select?: string;
}

const getItemApi = "{{vroomOrigin}}/{{apiVersion2}}/drives/{{driveId}}/items/{{itemId}}?{{parameters}}";

export function getItem(
  fetch: (url: string, init?: RequestInit) => Promise<Response>,
  driveId: string,
  itemId: string,
  options?: IGetItemOptions
): Promise<IItem> {
  const { expand, select } = options || {};
  const params: { [parameterName: string]: string } = {};

  if (select) {
    params.select = select;
  }

  if (expand && expand.length) {
    params.expand = `${expand.join(",")}`;
  }

  return graphResponse(fetch(formatURL(getItemApi, { driveId, itemId, parameters: new URLSearchParams(params) })));
}

const specialFolder = "{{vroomOrigin}}/{{apiVersion1}}/drives/{{driveId}}/special/{{folder}}";

export interface IGetSpecialFolderOptions {
  scenario?: TopLevelScenario;
}

export function getSpecialFolder(
  fetch: (url: string, init?: RequestInit) => Promise<Response>,
  driveId: string,
  folder: SpecialFolder,
  options?: IGetSpecialFolderOptions
): Promise<IFolder> {
  const init = options?.scenario ? { headers: { Scenario: options.scenario } } : undefined;
  return graphResponse(fetch(formatURL(specialFolder, { driveId, folder }), init));
}

const getPermissionsApi = "{{vroomOrigin}}/{{apiVersion1}}/drives/{{driveId}}/items/{{itemId}}/permissions";

export function getPermissions(
  fetch: (url: string, init?: RequestInit) => Promise<Response>,
  driveId: string,
  itemId: string
): Promise<IPermissions> {
  return graphResponse(fetch(formatURL(getPermissionsApi, { driveId, itemId }))).then(preparePermissions);
}
