import { IReadonlyObservableArray, ObservableArray, ObservableCollection } from "azure-devops-ui/Core/Observable";
import { IMenuItem, MenuButton, MenuItemType } from "azure-devops-ui/Menu";
import { css } from "azure-devops-ui/Util";
import { Location } from "azure-devops-ui/Utilities/Position";
import React from "react";
import { IGetSpecialFolderOptions } from "../../api/item";
import { SessionContext } from "../../contexts/session";
import { useSetting } from "../../hooks/usesetting";
import { FilterFolder, IFolder, SpecialFolder } from "../../types/item";
import * as Icons from "../illustration/icons";

const { FilterByHeader, FilterByAll } = window.Resources.Common;

const filterHeaderMenuItem: IMenuItem = {
  id: "filter-header",
  itemType: MenuItemType.Header,
  text: FilterByHeader
};

export interface IFilterMenuProps {
  className?: string;
  filterFolder: FilterFolder;
  getSpecialFolder: (driveId: string, specialFolder: SpecialFolder) => Promise<IFolder>;
  role?: string;
  selectFilter: (filterFolder: FilterFolder, folderId: string) => void;
}

export function FilterMenu(props: IFilterMenuProps): React.ReactElement {
  const { className, filterFolder, getSpecialFolder, role = "menuitem", selectFilter } = props;

  const sessionContext = React.useContext(SessionContext);

  const [, setUxSetting] = useSetting("uxSetting");

  const filterMenuItems = React.useMemo(
    () => new ObservableCollection<IMenuItem>(),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterFolder]
  );

  return (
    <MenuButton
      buttonClassName="bolt-icon-button icon-only"
      className="relative"
      contextualMenuProps={{
        anchorOffset: { horizontal: 0, vertical: 4 },
        anchorOrigin: { horizontal: Location.start, vertical: Location.end },
        menuOrigin: { horizontal: Location.start, vertical: Location.start },
        menuProps: {
          className: css(className, "compact-menu status-menu"),
          id: "filterFolder",
          items: filterMenuItems
        }
      }}
      hideDropdownIcon={true}
      iconProps={{
        render: (className?: string) => <Icons.Filter className={className} />
      }}
      key="filter-dropdown"
      onClick={() => {
        if (filterMenuItems.length === 0) {
          filterMenuItems.push(
            getFilterMenuItems(
              sessionContext.driveId,
              filterFolder,
              (filterFolder: FilterFolder, folderId: string) => {
                setUxSetting({ galleryFilter: { filterFolder, folderId } });
                selectFilter(filterFolder, folderId);
              },
              getSpecialFolder
            )
          );
        }
      }}
      role={role}
      subtle={true}
      tooltipProps={{
        showOnFocus: true,
        text: FilterByHeader
      }}
    >
      {filterFolder !== "onedrive" && <span className="coach-mark" />}
    </MenuButton>
  );
}

let photosPromise: Promise<IFolder> | undefined;
let samsungGalleryPromise: Promise<IFolder> | undefined;

export function getFilterMenuItems(
  driveId: string,
  filterFolder: FilterFolder,
  selectFilter: (filterFolder: FilterFolder, folderId: string) => void,
  getSpecialFolder: (driveId: string, folder: SpecialFolder, options?: IGetSpecialFolderOptions) => Promise<IFolder>
): IReadonlyObservableArray<IMenuItem> {
  // Add the static menu items to the initially returned array.
  const menuItems = new ObservableArray<IMenuItem>([
    filterHeaderMenuItem,
    {
      checked: filterFolder === "onedrive",
      className: "simple-checkbox",
      iconProps: {
        render: (className?: string) => <Icons.OneDrive className={className} />
      },
      id: "onedrive",
      onActivate: () => selectFilter("onedrive", "root"),
      readonly: true,
      text: FilterByAll
    }
  ]);

  // If we haven't retrieved the special folders yet, we will fetch them now.
  if (!photosPromise) {
    photosPromise = getSpecialFolder(driveId, "photos", { scenario: "BrowsePhoto" });
  }

  if (!samsungGalleryPromise) {
    samsungGalleryPromise = getSpecialFolder(driveId, "samsungGallery", { scenario: "BrowsePhoto" });
  }

  // Once we have resolved both of these we will add the menu items
  // if the special folder exists and has any children.
  Promise.allSettled([photosPromise, samsungGalleryPromise]).then(([photosResult, samsungResult]) => {
    const specialFolders: IMenuItem[] = [];

    if (photosResult.status === "fulfilled" && photosResult.value.folder && photosResult.value.folder.childCount) {
      specialFolders.push({
        checked: filterFolder === "photos",
        className: "simple-checkbox",
        iconProps: {
          render: (className?: string) => <Icons.Folder className={className} />
        },
        id: "photos",
        onActivate: () => selectFilter("photos", photosResult.value.id),
        readonly: true,
        text: photosResult.value.name
      });
    }

    if (samsungResult.status === "fulfilled" && samsungResult.value.folder && samsungResult.value.folder.childCount) {
      specialFolders.push({
        checked: filterFolder === "samsungGallery",
        className: "simple-checkbox",
        iconProps: {
          render: (className?: string) => <Icons.Folder className={className} />
        },
        id: "samsungGallery",
        onActivate: () => selectFilter("samsungGallery", samsungResult.value.id),
        readonly: true,
        text: samsungResult.value.name
      });
    }

    menuItems.push(...specialFolders);
  });

  return menuItems;
}

export function sanitizeFilter(filter?: { filterFolder: FilterFolder; folderId: string }): {
  filterFolder: FilterFolder;
  folderId: string;
} {
  if (filter && typeof filter.folderId === "string") {
    const { filterFolder, folderId } = filter;

    if (filterFolder === "photos" || filterFolder === "samsungGallery") {
      return { filterFolder, folderId: folderId.toString() };
    }
  }

  return { filterFolder: "onedrive", folderId: "root" };
}
